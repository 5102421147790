import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'

import { SectionEditButton } from 'components/buttons'
import Gallery from 'components/gallery'
import { IEditableSectionsTypes } from 'components/modals/org'

import { youtubeUrlValidator } from 'validators/orgStepValidator'
import { getYoutubeCode } from 'utils'

import { IBaseSectionProps } from './types'

export const OrgDescGallery = ({
  org,
  editable,
  onEditableClick
}: IBaseSectionProps) => {
  const { t } = useTranslation(['org_form', 'inputs'])
  const { locale } = useRouter()
  const openModal = useCallback(
    (
        modal: Extract<IEditableSectionsTypes, 'description' | 'companyGallery'>
      ) =>
      () => {
        onEditableClick?.({ modal })
      },
    [onEditableClick]
  )
  const gallery = useMemo(() => {
    const youtube =
      org.gallery.filter((url) => youtubeUrlValidator.safeParse(url).success) ??
      []
    return {
      youtube: youtube[0] ?? null,
      images: org.gallery.filter((url) => !youtube.includes(url)) ?? []
    }
  }, [org.gallery])

  const description = useMemo(() => {
    return (
      org?.descriptions?.find((item) => item.languageCode === locale)?.value ??
      org?.descriptions?.find((item) => item.isDefaultValue)?.value
    )
  }, [org?.descriptions, locale])

  return (
    <div className="pb-20">
      <section className="relative w-full py-5">
        {editable && (
          <SectionEditButton
            data-cy="edit-description-modal"
            className="absolute top-0 right-0"
            onClick={openModal('description')}
          />
        )}
        <div className="mb-2 flex justify-between">
          <h2 className="text-2xl font-bold tracking-wide">
            {t('org_form:companyOverview.title')}
            <i className="dot"></i>
          </h2>
        </div>
        <p className="mb-5 whitespace-pre-wrap text-gray-700">{description}</p>
      </section>
      <div className="relative flex">
        {editable && (
          <SectionEditButton
            className="absolute top-0 right-0 z-10"
            onClick={openModal('companyGallery')}
          />
        )}
        {gallery.images.length > 0 ? (
          <section className="relative mb-10 mt-3 h-60 w-full">
            <div className="mb-2 flex justify-between">
              <h4 className="text-lg font-bold tracking-wide">
                {t('org_form:companyGallery.title')}
                <i className="dot"></i>
              </h4>
            </div>
            <Gallery content={org.gallery ?? []} galleryName={org.legalName} />
          </section>
        ) : null}
      </div>
      {gallery.youtube && (
        <section className="w-full py-5">
          <div className="mb-2 flex justify-between">
            <h4 className="mb-2 text-lg font-bold tracking-wide">
              {t('org_form:companyGallery.video.title')}
              <i className="dot"></i>
            </h4>
          </div>
          <iframe
            width="100%"
            height="100%"
            className="aspect-video"
            src={`https://www.youtube.com/embed/${getYoutubeCode(
              gallery.youtube
            )}`}
            title="Company video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </section>
      )}
    </div>
  )
}

export default OrgDescGallery

import Image from 'next/future/image'

import { youtubeUrlValidator } from 'validators/orgStepValidator'

import { IGalleryModalItemProps } from '../types'

import VideoItem from './VideoItem'

const isSrcOfVideo = (src: string) => {
  if (youtubeUrlValidator.safeParse(src).success) {
    return true
  }
  return false
}

const GalleryModalItem = ({ src, alt, isActive }: IGalleryModalItemProps) => {
  const isVideo = isSrcOfVideo(src)

  if (isVideo) {
    return <VideoItem src={src} isActive={isActive} />
  }

  return (
    <div className="w-ful flex h-full items-center justify-center">
      <Image
        src={src}
        alt={alt}
        fill
        className="!inset-auto !h-full !w-full object-contain"
      />
    </div>
  )
}

export default GalleryModalItem
